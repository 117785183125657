import { render, staticRenderFns } from "./anagrafica.vue?vue&type=template&id=fe98e286&scoped=true"
import script from "./anagrafica.ts?vue&type=script&lang=ts&external"
export * from "./anagrafica.ts?vue&type=script&lang=ts&external"
import style0 from "./anagrafica.vue?vue&type=style&index=0&id=fe98e286&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe98e286",
  null
  
)

export default component.exports