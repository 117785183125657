import Vue from "vue";
import * as XLSX from 'xlsx';

/* load 'fs' for readFile and writeFile support */
import * as fs from 'fs';
XLSX.set_fs(fs);

import { Component, Prop, Watch } from "vue-property-decorator";
import { ElUpload } from "element-ui/types/upload";
import { importaEsenzioniUtils } from "./importaEsenzioniUtils";
import { esenzioniService } from "@/modules/offertaFormativa/services/esenzioniService";

@Component({})
export default class ImportazioneEsenzioni extends Vue {
  
  loading: { dataTable: boolean, file: boolean, import: boolean } = { dataTable: false, file: false, import: false };
  file: File;
  sheet: sheets.itemEsenzioni = null;
  
  validWoorkBookHeader: boolean = true;
  stepIndex: number = 0;

  limit: number = 20;

  corso: corso.item = null;
  periodoFormativo: periodoFormativo.item = null;
  fileDetail: { name: string; createDate: Date; createUser: string; lastUpdateDate: Date; lastUpdateUser: string; } = null;
  
  breakLoading: boolean = false;

  rowLimit: number = 1000;
  rowLimitExceeded: boolean = false;

  async created() {
  }

  async importa() {
    this.loading.import = true;
    if(!this.rowLimitExceeded){
      
      const loadingFullScreen = this.$loading({
        lock: true,
        text: `Caricamento delle esenzioni in corso...`,
        background: "rgba(0,0,0, 0.7)"
      });
      
      
      const res = await esenzioniService.AddManuali({esenzioniRichieste: this.sheet.rows.slice(0, Math.min(1001, this.sheet.rows.length)), filename: this.file.name}); 
      if(res.results.length != 0){
        this.handleDownloadResults(res);
        this.$message({
          message: `Esenzioni elaborate. È stato scaricato <strong>in automatico</strong> il file<br><strong>${res.filename}</strong><br>con i risultati dell'importazione.<br><strong>Il file contiene i risultati dell'importazione</strong>: si consiglia di <strong> consultarlo e conservarlo</strong>.`,
          showClose: true,
          dangerouslyUseHTMLString: true,
          type: "info",
          duration: 15 * 1000
        });
      }
      this.loading.import = false;
      this.invalidateSheet = true;
      loadingFullScreen.close();
    }
    this.$emit("complete");
  }

  fixData(data) {
    let o = "";
    let l = 0;
    let w = 10240;
    for (l; l < data.byteLength / w; ++l)
      o += String.fromCharCode.apply(
        null,
        new Uint8Array(data.slice(l * w, l * w + w))
      );
    o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
    return o;
  }
  uploadedFile: XLSX.WorkBook = null;
  parseFiles(file: File) {
    this.loading.file = true;
    this.file = file;
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = reader.result;
      const fixedData = this.fixData(data);
      const workbook = XLSX.read(btoa(fixedData), { type: "base64" });
      this.fileDetail = {
        name: this.file.name,
        createDate: workbook.Props ? workbook.Props.CreatedDate : null,
        createUser: workbook.Props ? workbook.Props.Author : null,
        lastUpdateDate: workbook.Props ? workbook.Props.ModifiedDate : null,
        lastUpdateUser: workbook.Props ? workbook.Props.LastAuthor : null
      };
      this.uploadedFile = workbook;
    };
    reader.readAsArrayBuffer(this.file);
  }


  @Watch("uploadedFile")
  async readWorkSheetChange(n: XLSX.WorkBook, o: XLSX.WorkBook) {
    this.sheet = null;
    if (n && n !== o && (n.SheetNames || []).length > 0) {
      this.loading.file = true;
      const sheetName = n.SheetNames[0];
      const sheet = n.Sheets[sheetName];

      const requiredColumns = ['codiceFiscale' , 'codicePerImport'];
      const headerLoadedColumns = XLSX.utils.sheet_to_json(sheet, { header: 1 })[0] as string[];
      this.validWoorkBookHeader = headerLoadedColumns && requiredColumns.every(s => headerLoadedColumns.includes(s))
      if (!this.validWoorkBookHeader) {
        this.loading.file = false;
        return;
      }

      const key = importaEsenzioniUtils.normalize(sheetName);
      await this.parseRows(sheet, (rows) => {
        this.sheet = {
          key: key,
          name: sheetName,
          limit: this.limit,
          page: 1,
          rows: rows,
          filterText: "",
          filterState: "all",
          total: 0,
          complete: 0,
          warning: 0,
          error: 0,
        };
        this.reloadSheetStatisticData(this.sheet);
        this.loading.file = false;
      });
    }
  }


  originalSheetRowsLength: number = 0;
  loadingFileRowsProcessing: string = "";
  esManuali: esenzioni.addManuale[] = [];
  async parseRows(page: XLSX.WorkSheet, callback: Function) {
    this.loadingFileRowsProcessing = "Caricamento del file in corso ..."
    let sheetToJson = XLSX.utils.sheet_to_json<sheets.esenzione>(page);
    if (!sheetToJson || sheetToJson.length <= 0) return [];

    if (sheetToJson.length > this.rowLimit) {
      this.rowLimitExceeded = true;
    }
    if(!this.rowLimitExceeded){
      this.originalSheetRowsLength = sheetToJson.length;
      let rows: esenzioni.addManuale[] = [];
      
      let index = 0;
      this.loadingFileRowsProcessing = `Inizializzazione verifica dati in corso...`
      for (index = 0; index < sheetToJson.length; index++) {
        if (this.breakLoading){
          break;
        }
        rows.push(await importaEsenzioniUtils.parseEsenzione(sheetToJson[index], index, this.esManuali));
        this.loadingFileRowsProcessing = `Verifica dell'esenzione ${index + 1} su ${this.originalSheetRowsLength} da importare`
      }
      
      callback.call(null, rows);
    }
  }

  handleUpload(file) {
    this.clear();
    this.parseFiles(file.raw);
  }

  private clear() {
    this.sheet = null;
    this.rowLimitExceeded = false;
    this.validWoorkBookHeader = false;
    if (this.$refs.xlsxUploader)
      (this.$refs.xlsxUploader as ElUpload).clearFiles();
  }

  private reloadSheetStatisticData(sheet: sheets.itemEsenzioni) {
   
    sheet.total = sheet.rows.length;
    sheet.complete =
      sheet.rows.length <= 0
        ? 0
        : (sheet.rows as any).length; 
    sheet.warning =
      sheet.rows.length <= 0
        ? 0
        : (sheet.rows as any).length ;
    sheet.error =
      sheet.rows.length <= 0
        ? 0
        : (sheet.rows as any).length; 
  }

  invalidateSheet:boolean = false;
  get validSheet() {
    return (this.sheet != null ? true : this.sheet != undefined ? true : false && this.sheet.error <= 0) && !this.invalidateSheet
  }

  validateRowClassName({ row, index }: { row: esenzioni.addManuale, index: number }) {
    return "success-row";
  }
  
  handleDownloadEmpty() {
    var wb = XLSX.utils.book_new();
    var sheetRows = XLSX.utils.json_to_sheet([{
      annoInizioPeriodoFormativo: "",
      codiceFiscale: "",
      codicePerImport: "",
    }]);
    XLSX.utils.book_append_sheet(wb, sheetRows, "EsenzioniTemplate");
    XLSX.writeFile(wb, "caricamento-esenzioni-template.xlsx");
  }

  async handleDownloadTipi() {
    var wb = XLSX.utils.book_new();
    const tipi = await esenzioniService.ListTipi();
    var arrayTipi = [];
    tipi.filter(a=>a.automatico==false).filter(a=>a.macroTipo > 100 && a.macroTipo <= 1000).sort((a,b)=> a.codicePerImport - b.codicePerImport).forEach(element => {
      arrayTipi.push({descrizione: element.descrizione, codicePerImport: element.codicePerImport.toString()})
    });
    
    var sheetRows = XLSX.utils.json_to_sheet(arrayTipi);
    XLSX.utils.book_append_sheet(wb, sheetRows, "Esenzioni");
    XLSX.writeFile(wb, "descrizioni-e-codici-esenzioni.xlsx");
  }

  async handleDownloadResults(res: esenzioni.risultatiAddManuali) {
    var wb = XLSX.utils.book_new();
    var arrayResults = [];
    res.results.forEach(element => {
      arrayResults.push({codiceFiscale: element.codiceFiscale, codicePerImport: element.codicePerImport, risultato: element.risultato});
    });
    this.$emit('closeEsenzioni');
    
    var sheetRows = XLSX.utils.json_to_sheet(arrayResults);
    XLSX.utils.book_append_sheet(wb, sheetRows, "RisultatoImportEsenzioni");
    XLSX.writeFile(wb, res.filename);
  }
}
